<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    hide-buttons
    class="checkout-form-wizard steps-transparent">

    <tab-content title="Información" icon="feather icon-user" >
      <Information :formData="formData" @next-step="formWizardNextStep"/>
    </tab-content>

    <tab-content title="Dirección" icon="feather icon-map" >
      <Directions :formData="formData" @next-step="formWizardNextStep"/>
    </tab-content>

    <tab-content title="Contacto" icon="feather icon-info" >
      <ContactsShool :formData="formData" @next-step="formWizardNextStep"/>
    </tab-content>

    <tab-content title="Persona de contacto" icon="feather icon-github" >
      <PersonaContact :formData="formData" @next-step="formWizardNextStep"/>
    </tab-content>

    <tab-content title="Social" icon="feather icon-share" >
      <Social :formData="formData" :modeView="modeView" @next-step="formWizardNextStep"/>
    </tab-content>

  </form-wizard>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BButton, BRow, BCol
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import Information from './Information.vue'
import Social from './Social.vue'
import PersonaContact from './PersonaContact.vue'
import ContactsShool from './ContactsShool.vue'
import Directions from './Directions'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton, 
    BRow, 
    BCol,
    FormWizard,
    TabContent,
    Information,
    Social,
    PersonaContact,
    ContactsShool,
    Directions,
  },
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    modeView(){
      if(this.$router.currentRoute.name === 'schools-view' || this.$router.currentRoute.name === 'schools-edit') {
        return this.$router.currentRoute.params.id 
      } else {
        return false
      }
    }
  },
  methods: {
    formWizardNextStep() {
      this.$refs.refFormWizard.nextTab()
    },
    async getData(){
      if (this.modeView) {
        this.formData = await this.$store.dispatch('schools/show', this.modeView)
      }
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
