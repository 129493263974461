<template>
  <div>
    <validation-observer ref="informationForm">
      <b-media class="mb-2">
        <template #aside>
          <div>
            <b-avatar
              ref="previewEl"
              :src="urlPreview ? urlPreview : formData.logo"
              :text="avatarText(formData.name)"
              :variant="`light-success`"
              size="90px"
              rounded/>
            <h4 class="mt-1 ml-2">
              Logo
            </h4>
          </div>
        </template>
        <h4 class="mb-1">
          {{ formData.name }}
        </h4>
        <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.click()">
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="uploadImage">
            <feather-icon
              icon="UploadIcon"
              class="d-inline mr-sm-50"/>
            <span class="d-none d-sm-inline">Subir</span>
          </b-button>
        </div>
      </b-media>

      <b-row>
        <b-col md="6">
          <b-form-group label="Nombre">
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required">
              <b-form-input v-model="formData.name"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Descripción">
            <validation-provider
              #default="{ errors }"
              name="descripcion"
              rules="required">
              <b-form-input v-model="formData.description"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-row>
          <b-col class="d-flex justify-content-end">
              <b-button variant="primary" block @click="next()">
                Siguiente
              </b-button>
            </b-col>
        </b-row>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
localize('es', es)

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      disabled: true,
      urlPreview: null,
    }
  },
  methods: {
    async next() {
      if (!await this.$refs.informationForm.validate()) {
        return
      }

      this.$emit('next-step')
    },
    avatarText(text){
      return avatarText(text)
    },
    uploadImage() {
      const { inputImageRenderer } = useInputImageRenderer(this.$refs.refInputEl, this.getImage)

      inputImageRenderer()
    },
    getImage(base64) {
      this.urlPreview = base64
      this.formData.logo = base64
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
