<template>
  <div>
    <validation-observer ref="directionsForm">
      <b-row>
        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Dirección">
              <validation-provider
                #default="{ errors }"
                name="dirección"
                rules="required">
                <b-form-input v-model="formData.address"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Ciudad">
              <validation-provider
                #default="{ errors }"
                name="ciudad"
                rules="required">
                <b-form-input v-model="formData.city"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
          <b-form-group label="Estado">
              <validation-provider
                #default="{ errors }"
                name="estado"
                rules="required">
                <b-form-input v-model="formData.state"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>

        <b-row>
          <b-col class="d-flex justify-content-end">
              <b-button variant="primary" block @click="next()">
                Siguiente
              </b-button>
            </b-col>
        </b-row>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, } from '@validations'
import { localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
localize('es', es)

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, ValidationProvider,
    ValidationObserver,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required
    }
  },
  methods: {
    async next() {
      if (!await this.$refs.directionsForm.validate()) {
        return
      } 
      this.$emit('next-step')
    },
  },  
}
</script>